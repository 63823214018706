export enum TeamSeasonStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  COMPLETED = 'Completed',
  ARCHIVED = 'Archived',
}

export interface TeamSeason {
  id: string;
  label: string;
  description: string;
  imageURL: string;
  startDate: Date;
  endDate: Date;
  status: TeamSeasonStatus;
  archived: boolean;
  archivedDate?: Date;
  formId: string;
  scheduleURLs?: string[];
}

export interface TeamSeasonGroup {
  id: string;
  label: string;
  status: TeamSeasonStatus;
  description: string;
  archived: boolean;
  archivedDate?: Date;
  imageURL?: string;
  seasonIds: string[];
}
